import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  signOut as fbSignOut,
  onAuthStateChanged as fbOnAuthStateChanged,
} from 'firebase/auth';
// import 'firebase/auth';
// import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
};

export const initialize = () => initializeApp(firebaseConfig);

export const signInWithEmailAndPassword = ({ email, password }) =>
  fbSignInWithEmailAndPassword(getAuth(), email, password);

export const signOut = () => fbSignOut(getAuth());

export const onAuthStateChanged = (cb) => {
  fbOnAuthStateChanged(getAuth(), cb);
};

// export const getFirestore = () => firebase.firestore();
