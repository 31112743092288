import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  signOut as fbSignOut,
  onAuthStateChanged as fbOnAuthStateChanged,
  sendPasswordResetEmail as fbSendPasswordResetEmail,
  confirmPasswordReset as fbConfirmPasswordReset,
  verifyPasswordResetCode as fbVerifyPasswordResetCode,
  updatePassword as fbUpdatePassword,
} from 'firebase/auth';
import { initialize } from '../firebase/firebase';

import { UserNotAuthenticatedError, UserRequiresRecentLogin } from '../errors';

const REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login';

export const AuthContext = React.createContext({});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const auth = useRef();

  useEffect(() => {
    const app = initialize();
    auth.current = initializeAuth(app, {
      persistence: [indexedDBLocalPersistence, browserLocalPersistence],
    });

    setIsInitialized(false);

    setUser(auth.current.currentUser);

    const unsubscribe = fbOnAuthStateChanged(auth.current, (userAuth) => {
      setUser(userAuth);
      setIsInitialized(true);
    });

    return unsubscribe;
  }, []);

  const signIn = ({ email, password }) => {
    return fbSignInWithEmailAndPassword(auth.current, email, password);
  };

  const signOut = () => {
    return fbSignOut(auth.current);
  };

  const sendPasswordResetEmail = (email) => {
    return fbSendPasswordResetEmail(auth.current, email);
  };

  const updatePassword = (password) => {
    const { currentUser } = auth.current;

    if (!currentUser) {
      throw new UserNotAuthenticatedError();
    }

    return fbUpdatePassword(currentUser, password).catch((e) => {
      if (e.code === REQUIRES_RECENT_LOGIN) {
        throw new UserRequiresRecentLogin();
      }

      throw e;
    });
  };

  const verifyPasswordResetCode = (actionCode) => {
    return fbVerifyPasswordResetCode(auth.current, actionCode);
  };

  const confirmPasswordReset = ({ actionCode, newPassword }) => {
    return fbConfirmPasswordReset(auth.current, actionCode, newPassword);
  };

  const value = useMemo(() => {
    return {
      user,
      isInitialized,
      signIn,
      signOut,
      sendPasswordResetEmail,
      updatePassword,
      verifyPasswordResetCode,
      confirmPasswordReset,
    };
  }, [
    user,
    isInitialized,
    signIn,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
    verifyPasswordResetCode,
    confirmPasswordReset,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
