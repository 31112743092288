/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import { usePageContext } from '../contexts/page-context';

const LocalizedLink = ({ to, children, ...props }) => {
  const {
    pageContext: { langPath },
  } = usePageContext();
  const toPath = to === null ? '' : to;

  return (
    <Link {...props} to={`${langPath}/${toPath}`}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
