import React, { useState } from 'react';
import get from 'lodash/get';
import Footer from './Footer';
import Header from './Header';
import SEO from './SEO';
import MobileMenu from './MobileMenu';
import './all.sass';
import './fonts.css';

const Layout = ({ children, pageContext, data, location }) => {
  const { lang, shortLang, isHeaderIncluded, isFooterIncluded, isSeoIncluded = true } = pageContext;
  const { pathname } = location;
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const contact = get(data, 'prismicFooter.data.contact');
  const copyright = get(data, 'prismicFooter.data.copyright');
  const disclaimer = get(data, 'prismicDisclaimer.data.text');
  const whyHireMe = get(data, 'prismicWhyHireMe.data');
  const reviewEdges = get(data, 'googleReviews.edges');

  return (
    <>
      {isSeoIncluded && <SEO pathname={pathname} lang={shortLang} />}
      <MobileMenu isVisible={isMobileMenuVisible} onSelectedItem={() => setIsMobileMenuVisible(false)} />
      {isHeaderIncluded && (
        <Header isMobileMenuVisible={isMobileMenuVisible} setIsMobileMenuVisible={setIsMobileMenuVisible} />
      )}
      <div>{children}</div>
      {isFooterIncluded && (
        <Footer
          contact={contact}
          copyright={copyright}
          disclaimer={disclaimer}
          reviews={reviewEdges.map(({ node }) => node)}
          whyHireMe={whyHireMe}
          lang={lang}
        />
      )}
    </>
  );
};

export default Layout;
