export default [
  {
    key: 'tax_law_service',
    links: {
      en: {
        path: 'services/taxes-american-expats',
        label: 'services',
      },
      es: {
        path: 'servicios/impuestos-expatriados-americanos',
        label: 'servicios',
      },
    },
  },
  {
    key: 'about',
    links: {
      en: {
        path: 'about',
        label: 'about me',
      },
      es: {
        path: 'sobre-mi',
        label: 'sobre mi',
      },
    },
  },
  {
    key: 'blog',
    links: {
      en: {
        path: 'blog',
        label: 'blog',
      },
      es: {
        path: 'blog',
        label: 'blog',
      },
    },
  },
  {
    key: 'ebook',
    links: {
      en: {
        path: 'expat-taxes-ebook',
        label: 'ebook',
      },
      es: {
        path: 'ebook-impuestos-expatriados',
        label: 'ebook',
      },
    },
  },
  {
    key: 'contact',
    links: {
      en: {
        path: 'contact',
        label: 'contact',
      },
      es: {
        path: 'contacto',
        label: 'contacto',
      },
    },
  },
];
