/* eslint-disable max-classes-per-file */
export class UserNotAuthenticatedError extends Error {
  constructor(message) {
    super(message || 'User not authenticated');
  }
}

export class UserRequiresRecentLogin extends Error {
  constructor(message) {
    super(message || 'Credential too old. Please log in again');
  }
}
