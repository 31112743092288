/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SocialMediaLinks from './SocialMediaLinks';
import LanguageSelector from './LanguageSelector';
import CurrencySelector from './CurrencySelector';
import LocalizedLink from './LocalizedLink';
import { usePageContext } from '../contexts/page-context';
import { useAuthContext } from '../contexts/auth-context';
import navLinks from '../data/nav-links';
import siteMetadata from '../siteMetadata';
import { getShortLang } from '../utils';

const MobileMenu = ({ isVisible, className, itemsClassname, onSelectedItem = () => {} }) => {
  const ref = useRef(null);
  const {
    pageContext: { shortLang: pageContextShortLang, lang },
  } = usePageContext();
  const { user, signOut } = useAuthContext();
  const { t } = useTranslation();

  const shortLang = pageContextShortLang || getShortLang(siteMetadata.defaultLang);

  const [links, setLinks] = useState(navLinks);
  const [backLinks, setBackLinks] = useState(null);
  const [menuLabel, setMenuLabel] = useState(null);
  const [isOpenedOnce, setIsOpenedOnce] = useState(false);

  if (!navLinks) {
    return null;
  }

  const hideBodyScroll = () => {
    // eslint-disable-next-line
    document.body.style.overflow = 'hidden';
  };

  const showBodyScroll = () => {
    // eslint-disable-next-line
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      hideBodyScroll();
      setIsOpenedOnce(true);
    } else {
      timeoutId = setTimeout(() => {
        ref.current.scroll({
          top: 0,
          left: 0,
        });
        showBodyScroll();
      }, 300);
    }

    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  const onClick = ({ link, parentLinks }) => {
    setMenuLabel(link.links[shortLang].label);
    setLinks(link.children);
    setBackLinks(parentLinks);
  };

  const onSelectedLink = (key) => {
    showBodyScroll();
    setLinks(navLinks);
    setBackLinks(null);
    setMenuLabel(null);
    onSelectedItem(key);
  };

  const onBackLink = () => {
    setMenuLabel(null);
    setLinks(backLinks);
    setBackLinks(null);
  };

  const onSignOut = () => {
    signOut();
    onSelectedItem('logout');
  };

  return (
    <div
      className={`menu fixed top-14 z-50 h-screen w-screen transform overflow-auto bg-white pb-64 text-left duration-300 xl:hidden ${className} ${
        isVisible ? '' : '-translate-x-full'
      }`}
      ref={ref}
    >
      <div className={`${isOpenedOnce ? 'flex w-full flex-col' : 'hidden'}`}>
        <div className="relative flex w-full items-center py-6 px-4 text-center text-xl capitalize">
          {backLinks && (
            <button type="button" className="absolute -mx-4" onClick={() => onBackLink()}>
              <div className="flex items-center px-4">
                <FaAngleLeft size={32} />
              </div>
            </button>
          )}
          <div className="absolute left-1/2 flex -translate-x-1/2 transform items-center text-base capitalize">
            {menuLabel || t('mobileMenu.menu')}
          </div>
        </div>
        {user ? (
          <>
            <LocalizedLink
              onClick={() => onSelectedLink('my-user-portal')}
              className={`flex justify-center bg-light-500 py-8 px-4 text-center text-xl uppercase text-white no-underline ${itemsClassname}`}
              to={lang === siteMetadata.defaultLang ? 'my-account/tax-forms' : 'mi-cuenta/formularios-impuestos'}
            >
              {t('mobileMenu.myAccount')}
            </LocalizedLink>
            <LocalizedLink
              onClick={onSignOut}
              className={`border-t flex justify-center border-solid border-white bg-light-500 py-8 px-4 text-center text-xl uppercase text-white no-underline ${itemsClassname}`}
              to=""
            >
              {t('mobileMenu.logOut')}
            </LocalizedLink>
          </>
        ) : (
          <LocalizedLink
            key="login"
            onClick={() => onSelectedLink('login')}
            className={`flex justify-center bg-light-500 py-8 px-4 text-center text-xl uppercase text-white no-underline ${itemsClassname}`}
            to={lang === siteMetadata.defaultLang ? 'my-account/login' : 'mi-cuenta/inicio-sesion'}
          >
            {t('mobileMenu.login')}
          </LocalizedLink>
        )}
        <nav className="flex flex-col">
          {links.map((l) =>
            l.children ? (
              <button
                type="button"
                key={l.key}
                onClick={() => onClick({ link: l, parentLinks: links })}
                className={`border-t relative flex items-center border-solid border-light-500 py-8 px-4 text-xl uppercase no-underline ${itemsClassname}`}
              >
                <div className="flex w-full items-center justify-center text-center">
                  <div>{l.links[shortLang].label}</div>
                </div>
                <div className="absolute right-4 flex w-full justify-end">
                  <FaAngleRight size="24" />
                </div>
              </button>
            ) : (
              <LocalizedLink
                key={l.key}
                onClick={() => onSelectedLink(l.key)}
                className={`border-t flex justify-center border-solid border-light-500 py-8 px-4 text-center text-xl uppercase no-underline ${itemsClassname}`}
                to={l.links[shortLang].path}
              >
                {l.links[shortLang].label}
              </LocalizedLink>
            ),
          )}
        </nav>
        <div className="border-t -ml-4 flex items-center justify-center border-solid border-light-500 pl-4 pt-4">
          <SocialMediaLinks size={32} className="flex items-center p-4 text-light-500" />
        </div>
        <div className="flex items-center justify-center space-x-4">
          <CurrencySelector size={18} />
          <LanguageSelector size={18} onSelected={() => onSelectedItem()} />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
