import navLinks from './nav-links';

export default [
  ...navLinks,
  {
    key: 'privacy_policy',
    links: {
      en: {
        path: 'privacy-policy',
        label: 'privacy policy',
      },
      es: {
        path: 'politica-privacidad',
        label: 'política de privacidad',
      },
    },
  },
  {
    key: 'impressum',
    links: {
      en: {
        path: 'impressum',
        label: 'impressum',
      },
      es: {
        path: 'impressum',
        label: 'impressum',
      },
    },
  },
];
