import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import siteMetadata from '../siteMetadata';

function SEO({ description, lang, meta, image: metaImage, title, pathname, noIndex }) {
  const {
    siteMetaImage: {
      childImageSharp: { resize: siteMetaImage },
    },
  } = useStaticQuery(
    graphql`
      query {
        siteMetaImage: file(relativePath: { eq: "home_image_cropped.jpg" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    `,
  );

  const metaDescription = description || siteMetadata.description[lang];
  const image =
    metaImage && metaImage.src
      ? { ...metaImage, url: `${metaImage.src}` }
      : { ...siteMetaImage, url: `${siteMetadata.siteUrl}${siteMetaImage.src}` };

  const canonical = pathname ? `${siteMetadata.siteUrl}${pathname}` : null;
  const metaTitle = title || siteMetadata.title[lang];
  const { site } = siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${site} - ${metaTitle}`}
      titleTemplate={`%s | ${siteMetadata.site}`}
      link={[
        {
          rel: 'icon',
          href: `${withPrefix('/')}img/favicon-16x16.png`,
          sizes: '16x16',
          type: 'image/png',
        },
        {
          rel: 'icon',
          href: `${withPrefix('/')}img/favicon-32x32.png`,
          sizes: '32x32',
          type: 'image/png',
        },
        {
          rel: 'apple-touch-icon',
          href: `${withPrefix('/')}img/apple-touch-icon.png`,
          sizes: '180x180',
        },
        ...(canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []),
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: siteMetadata.keywords[lang].join(','),
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: 'og:image',
                  content: image.url,
                },
                {
                  property: 'og:image:width',
                  content: image.width,
                },
                {
                  property: 'og:image:height',
                  content: image.height,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ],
        )
        .concat(
          noIndex
            ? [
                {
                  name: 'robots',
                  content: 'noindex',
                },
              ]
            : [],
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  title: null,
  description: null,
  lang: 'en',
  meta: [],
  image: null,
  pathname: '/',
};

export default SEO;
