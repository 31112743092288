import React, { useState, useRef, useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import LocalizedLink from './LocalizedLink';

const Dropdown = ({ items = [], label, className, buttonClassName, itemsClassname }) => {
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const onBlurItem = key => {
    const lastItem = items[items.length - 1];

    if (lastItem && key === lastItem.key) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    const escFunction = event => {
      if (event.keyCode === 27) {
        setIsExpanded(false);
      }
    };

    // eslint-disable-next-line
    document.addEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line
    document.addEventListener('keydown', escFunction, false);

    return () => {
      // eslint-disable-next-line
      document.removeEventListener('mousedown', handleClickOutside);
      // eslint-disable-next-line
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <div className={`relative ${className}`}>
      <button
        type="button"
        className={`flex items-center focus:outline-none focus:underline ${buttonClassName}`}
        onClick={() => setIsExpanded(!isExpanded)}
        ref={buttonRef}
      >
        <span>{label}</span>
        <FaAngleDown
          size={18}
          className={`absolute right-2 transform transition duration-300 ${isExpanded ? 'rotate-180' : 'rotate-0'}`}
        />
      </button>

      <div
        className={`absolute border border-solid border-gray-300 text-base z-20 bg-white rounded-lg shadow-xl p-4 flex-col w-screen max-w-xs -left-20 transform transition-all ease-in-out duration-300 ${
          isExpanded ? 'opacity-100 visible translate-y-6' : 'opacity-0 invisible translate-y-0'
        }`}
        ref={wrapperRef}
      >
        {items.map(item => (
          <LocalizedLink
            key={item.key}
            to={item.link}
            onBlur={() => onBlurItem(item.key)}
            onClick={() => setIsExpanded(false)}
            className={`"no-underline hover:underline ${itemsClassname}`}
          >
            <div className="mb-4 flex items-center">
              {item.icon && <div className="mr-2">{item.icon}</div>}
              <div>{item.label}</div>
            </div>
          </LocalizedLink>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
