import React, { useRef } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Slider = ({ children, isArrowsDisplayed = true, itemRef, className }) => {
  const sliderRef = useRef(null);

  const moveSliderToPreviousItem = () => {
    if (sliderRef.current.scrollLeft > itemRef.current.offsetWidth) {
      sliderRef.current.scrollLeft -= itemRef.current.offsetWidth;
    } else {
      sliderRef.current.scrollLeft = 0;
    }
  };

  const moveSliderToNextItem = () => {
    sliderRef.current.scrollLeft += itemRef.current.offsetWidth;
  };

  return (
    <div className={`lg:flex lg:items-center h-full ${!isArrowsDisplayed ? 'lg:px-12' : ''}`}>
      {isArrowsDisplayed && (
        <button
          type="button"
          alt="previous item in slider"
          onClick={() => moveSliderToPreviousItem()}
          className="hidden lg:block lg:p-2 lg:relative lg:bottom-4 lg:focus:outline-none transform hover:scale-125 transition-transform duration-300"
        >
          <FaAngleLeft size={32} />
        </button>
      )}
      <div ref={sliderRef} className={`slider flex overflow-x-auto w-full ${className}`}>
        <div className={`flex px-4 -ml-4 mb-12 lg:px-0 ${!isArrowsDisplayed ? 'lg:justify-center' : ''}`}>
          {children}
        </div>
      </div>
      {isArrowsDisplayed && (
        <button
          type="button"
          alt="next item in slider"
          onClick={() => moveSliderToNextItem()}
          className="hidden lg:block lg:p-2 lg:relative lg:bottom-4 lg:focus:outline-none transform hover:scale-125 transition-transform duration-300"
        >
          <FaAngleRight size={32} />
        </button>
      )}
    </div>
  );
};

export default Slider;
