import React, { useRef } from 'react';
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Slider from './Slider';
import { usePageContext } from '../contexts/page-context';
import { getExcerpt } from '../utils';

const getStars = (rate) => {
  const stars = [];

  for (let i = 1; i <= 5; i += 1) {
    if (i <= rate) {
      stars.push(<FaStar />);
    } else {
      const difference = 1 - (i - rate);

      if (difference < 0.25) {
        stars.push(<FaRegStar />);
      } else if (difference < 0.75) {
        stars.push(<FaStarHalfAlt />);
      } else if (difference < 1) {
        stars.push(<FaStar />);
      } else {
        stars.push(<FaRegStar />);
      }
    }
  }

  return stars;
};

const getReviewsWithContent = ({ reviews, length = 3 }) => {
  return reviews.filter((review) => review.body).slice(0, length);
};

function GoogleReviews({ reviews = [], className }) {
  const reviewsCarouselRef = useRef(null);
  const {
    pageContext: { reviewsRate, reviewsCount },
  } = usePageContext();
  const { t } = useTranslation();

  if (!reviews.length) {
    return null;
  }

  const reviewsWithContent = getReviewsWithContent({ reviews });

  return (
    <div className={`flex w-full flex-col items-center bg-cream pt-8 ${className}`}>
      <div className="mb-8 flex-1 text-4xl">{t('googleReviews.clientsTrustMe')}</div>
      <div className="flex flex-row items-center justify-center font-serifdisplay">
        <div className="mb-2 flex flex-row justify-center px-2 text-3xl text-gold">{getStars(reviewsRate)}</div>
        <div className="">{reviewsRate}</div>
        <span className="px-2 text-2xl text-light-500" style={{ lineHeight: 0 }}>
          •
        </span>
        <div className="">
          {t('googleReviews.reviews')}
          <span className="ml-1">{reviewsCount}</span>
        </div>
      </div>
      <a
        href="https://g.page/sabalier-law?share"
        target="_blank"
        rel="noopener noreferrer"
        className="mb-8 px-2 font-serifdisplay capitalize underline"
      >
        {t('googleReviews.seeMoreReviews')}
      </a>
      <div className="container mx-auto">
        <Slider className="lg:block" isArrowsDisplayed={false} itemRef={reviewsCarouselRef}>
          {reviewsWithContent.map((review) => (
            <div
              className={`flex flex-none ${reviewsWithContent.length > 1 ? 'w-75vw' : 'w-full'} ${
                reviewsWithContent.length > 2 ? 'lg:w-1/3' : 'lg:w-1/2'
              }`}
              ref={reviewsCarouselRef}
            >
              <div key={review.source} className="ml-4 flex w-full flex-col items-center py-4 px-2 text-center">
                <div className="mb-8 flex flex-1 font-bold italic">
                  &ldquo;
                  {getExcerpt(review.body, 25)}
                  &rdquo;
                </div>
                <div className="mb-1 flex capitalize text-gold">{getStars(review.rating)}</div>
                <div className="text-sm capitalize">{review.source}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export const GoogleReviewsQuery = graphql`
  fragment GoogleReviewsQuery on Query {
    googleReviews: allGoogleReview(
      sort: { position: ASC }
      limit: 10
      filter: { lang: { eq: $shortLang } }
    ) {
      edges {
        node {
          rating
          body
          source
        }
      }
    }
  }
`;

export default GoogleReviews;
