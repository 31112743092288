import React from 'react';
import LocalizedLink from './LocalizedLink';
import Dropdown from './Dropdown';
import { usePageContext } from '../contexts/page-context';
import navLinks from '../data/nav-links';
import siteMetadata from '../siteMetadata';
import { getShortLang } from '../utils';

const NavBar = ({ onClick = () => {}, className }) => {
  const {
    pageContext: { shortLang: pageContextShortLang },
  } = usePageContext();

  if (!navLinks) {
    return null;
  }

  const shortLang = pageContextShortLang || getShortLang(siteMetadata.defaultLang);

  return (
    <nav className="flex flex-col lg:flex-row lg:items-center">
      {navLinks.map((l) =>
        l.children ? (
          <Dropdown
            label={l.links[shortLang].label}
            items={l.children.map((item) => ({
              ...item,
              link: item.links[shortLang].path,
              label: item.links[shortLang].label,
            }))}
            className={className}
            buttonClassName="px-8 uppercase"
            onClick={() => onClick(l.key)}
          />
        ) : (
          <LocalizedLink
            key={l.key}
            onClick={() => onClick(l.key)}
            className={`px-6 focus:outline-none focus:underline uppercase ${className}`}
            to={l.links[shortLang].path}
          >
            {l.links[shortLang].label}
          </LocalizedLink>
        ),
      )}
    </nav>
  );
};

export default NavBar;
