import React from 'react';
import LocalizedLink from './LocalizedLink';
import { usePageContext } from '../contexts/page-context';
import footerLinks from '../data/footer-links';
import siteMetadata from '../siteMetadata';
import { getShortLang } from '../utils';

const FooterLinks = ({ onClick = () => {}, className }) => {
  const {
    pageContext: { shortLang: pageContextShortLang },
  } = usePageContext();

  const shortLang = pageContextShortLang || getShortLang(siteMetadata.defaultLang);

  const getFooterLinkWithChildren = footerLink => {
    const childrenLinks = footerLink.children.map(l => (
      <LocalizedLink
        key={l.key}
        onClick={() => onClick(l.key)}
        className={`text-sm underline ${className}`}
        to={l.links[shortLang].path}
      >
        {l.links[shortLang].label}
      </LocalizedLink>
    ));

    return [
      <div key={footerLink.key} className={`no-underline ${className}`}>
        {footerLink.links[shortLang].label}
      </div>,
      ...childrenLinks,
    ];
  };

  return (
    <>
      {footerLinks &&
        footerLinks.map(l =>
          l.children ? (
            getFooterLinkWithChildren(l)
          ) : (
            <LocalizedLink
              key={l.key}
              onClick={() => onClick(l.key)}
              className={className}
              to={l.links[shortLang].path}
            >
              {l.links[shortLang].label}
            </LocalizedLink>
          ),
        )}
    </>
  );
};

export default FooterLinks;
