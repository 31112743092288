import React from 'react';
import { graphql } from 'gatsby';

const PostDisclaimer = ({ disclaimer, className }) => {
  return <div className={`text-xs text-black italic ${className}`}>{disclaimer}</div>;
};

export const PostDisclaimerFragment = graphql`
  fragment PostDisclaimerFragment on PrismicDisclaimer {
    data {
      text
    }
  }
`;

export default PostDisclaimer;
