/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { navigate } from 'gatsby';
import { FaAngleDown, FaGlobe } from 'react-icons/fa';
import { setCookie } from 'tiny-cookie';
import siteMetadata from '../siteMetadata';
import { getShortLang, getLangPath } from '../utils';
import { usePageContext } from '../contexts/page-context';

const LanguageSelector = ({ onSelected = () => {}, size = 24, className }) => {
  const {
    pageContext: { lang, alternateLanguages },
  } = usePageContext();

  const onSelectedLang = (event) => {
    const selectedLang = event.target.value;
    setCookie('nf_lang', getShortLang(selectedLang), { expires: 7 });

    if (!alternateLanguages) {
      navigate(`${getLangPath(selectedLang)}/`);
    } else {
      const selectedLangDocument = alternateLanguages[selectedLang];

      if (selectedLangDocument) {
        navigate(selectedLangDocument.path);
      } else {
        navigate(`${getLangPath(selectedLang)}/`);
      }
    }

    onSelected(selectedLang);
  };

  return (
    <div className="flex items-center relative cursor-pointer">
      <FaGlobe size={size} className="absolute left-2" />
      <select
        aria-label="language"
        className={`block w-full bg-transparent py-2 pr-6 pl-8 uppercase cursor-pointer focus:outline-none appearance-none focus:underline z-10 ${className}`}
        value={lang}
        onChange={onSelectedLang}
        style={{ WebkitAppearance: 'none' }}
      >
        {siteMetadata.langs.map((l) => (
          <option key={l.key} value={l.key}>
            {l.label.toUpperCase()}
          </option>
        ))}
      </select>
      <FaAngleDown size={size} className="absolute right-0" />
    </div>
  );
};

export default LanguageSelector;
