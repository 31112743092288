import React from 'react';
import { graphql } from 'gatsby';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import NavBar from './NavBar';
import LocalizedLink from './LocalizedLink';
import LanguageSelector from './LanguageSelector';
import CurrencySelector from './CurrencySelector';
import { usePageContext } from '../contexts/page-context';
import { useAuthContext } from '../contexts/auth-context';
import siteMetadata from '../siteMetadata';

const Header = ({ isMobileMenuVisible, setIsMobileMenuVisible, className }) => {
  const {
    pageContext: { lang },
  } = usePageContext();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  return (
    <header
      className={`border-b sticky top-0 w-full border-solid border-light-500 bg-white font-serifdisplay font-normal transition-colors duration-500 ${
        isMobileMenuVisible ? 'z-30' : 'z-50'
      } ${className}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="py-3 px-4 xl:py-1">
        <div className="container mx-auto hidden flex-row items-center justify-between xl:flex">
          <LocalizedLink to="" title="Logo">
            <StaticImage
              src="../img/logo_sabalier_law.png"
              alt="log"
              placeholder="blurred"
              layout="fixed"
              width={150}
            />
          </LocalizedLink>

          <div className="hidden xl:flex ">
            <NavBar className="text-base uppercase no-underline hover:underline" />
          </div>

          <div className="hidden flex-row xl:flex">
            <div className="flex flex-none items-center">
              <CurrencySelector size={18} className="text-base" />
              <LanguageSelector size={18} className="text-base" />
              {user ? (
                <LocalizedLink
                  to={lang === siteMetadata.defaultLang ? 'my-account/tax-forms' : 'mi-cuenta/formularios-impuestos'}
                >
                  <div className="ml-4 rounded-3xl bg-light-500 px-4 py-1 uppercase text-white">
                    {t('header.myAccount')}
                  </div>
                </LocalizedLink>
              ) : (
                <LocalizedLink to={lang === siteMetadata.defaultLang ? 'my-account/login' : 'mi-cuenta/inicio-sesion'}>
                  <div className="ml-4 rounded-3xl bg-light-500 px-6 py-1 uppercase text-white">
                    {t('header.login')}
                  </div>
                </LocalizedLink>
              )}
            </div>
          </div>
        </div>

        <div className="xl:hidden">
          <div className="flex justify-between" data-target="navMenu">
            <LocalizedLink to="" title="Logo">
              <StaticImage
                src="../img/logo_sabalier_law.png"
                alt="log"
                placeholder="blurred"
                layout="fixed"
                width={100}
              />
            </LocalizedLink>
            <div className="flex items-center justify-center">
              <button
                type="button"
                className="focus:outline-none z-30 -my-2 -mx-2 ml-2 p-2"
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
                aria-label="open menu"
              >
                {isMobileMenuVisible ? <FaTimes size={32} /> : <FaBars size={32} />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export const NavbarFragment = graphql`
  fragment NavbarFragment on PrismicNavbar {
    data {
      internal_links {
        label
        link
      }
      social_media_links {
        service
        link
      }
    }
  }
`;

export default ({ isMobileMenuVisible, setIsMobileMenuVisible }) => (
  <Header isMobileMenuVisible={isMobileMenuVisible} setIsMobileMenuVisible={setIsMobileMenuVisible} />
);
