import React, { useContext, useEffect, useState } from 'react';
import siteMetadata from '../siteMetadata';

export const PageContext = React.createContext({});

export const usePageContext = () => useContext(PageContext);

export const PageContextProvider = ({ pageContext: pageContextProp, children }) => {
  const [pageContext, setPageContext] = useState({
    ...pageContextProp,
    currency: siteMetadata.defaultCurrency,
  });

  const setCurrency = currency => {
    setPageContext(previousContext => ({ ...previousContext, currency }));
  };

  useEffect(() => {
    setPageContext(prevPageContext => ({ ...prevPageContext, ...pageContextProp }));
  }, [pageContextProp]);

  return <PageContext.Provider value={{ pageContext, setCurrency }}>{children}</PageContext.Provider>;
};

export default PageContextProvider;
