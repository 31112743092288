import React from 'react';
import { PrismicRichText } from '@prismicio/react';

const richTextComponents = {
  paragraph: ({ node, text, children }) => {
    if (node?.label === 'iframe') {
      // eslint-disable-next-line
      return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    return <p>{children}</p>;
  },
};

export const HTMLContent = ({ content, className }) => {
  // eslint-disable-next-line
  return <div className={className} dangerouslySetInnerHTML={{ __html: content }} />;
};

export const RichTextContent = ({ content }) => {
  return <PrismicRichText field={content} components={richTextComponents} />;
};

const Content = ({ content, className }) => <div className={className}>{content}</div>;

HTMLContent.propTypes = Content.propTypes;

export default Content;
