import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import FooterLinks from './FooterLinks';
import { HTMLContent } from './Content';
import SocialMediaLinks from './SocialMediaLinks';
import PostDisclaimer from './PostDisclaimer';
import WhyHireMe from './WhyHireMe';
import GoogleReviews from './GoogleReviews';
import { isGoogleReviewsEnabled } from '../utils';

const Footer = ({ contact, copyright, disclaimer, reviews, whyHireMe, className }) => {
  const { t } = useTranslation();

  const whyHireMetitle = whyHireMe ? whyHireMe.title.text : '';
  const whyHireMeHighlights = whyHireMe ? whyHireMe.highlights : [];

  return (
    <div className={className}>
      <div className="flex flex-col items-center bg-white">
        <WhyHireMe title={whyHireMetitle} highlights={whyHireMeHighlights} className="container mx-auto py-8 px-4" />
        {isGoogleReviewsEnabled() && <GoogleReviews className="bg-cream" reviews={reviews} />}
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto flex flex-col px-4 text-center text-base lg:flex-row ">
          <div className="container mx-auto flex flex-1 flex-col py-4">
            <FooterLinks className="p-3 font-bold capitalize underline lg:px-8" />
          </div>
          <div className="footer-contact container mx-auto flex flex-1 flex-col py-8">
            {contact && <HTMLContent className="mb-8" content={contact.html} />}
            <div className="flex flex-col items-center">
              <div className="mb-8 flex justify-center">
                <SocialMediaLinks size={32} className="px-4 text-light-500" />
              </div>
              <PostDisclaimer disclaimer={disclaimer} className="post-disclaimer " />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light-100 py-6 text-center">
        {copyright}
        &nbsp;
        {new Date().getFullYear()}
      </div>
      <div className="flex justify-center text-sm">
        <div className="flex py-4">
          {t('footer.madeWithSpecial')}
          &nbsp;
          <FaHeart className="relative top-1 text-red-600" />
          &nbsp;
          {t('footer.by')}
        </div>
        <a className="px-1 py-4 underline" href="https://www.orsaorin.com" target="_blank" rel="noopener noreferrer">
          Javier Ortiz Saorin
        </a>
      </div>
    </div>
  );
};

export const FooterFragment = graphql`
  fragment FooterFragment on PrismicFooter {
    data {
      contact {
        html
      }
      copyright
      internal_links {
        label
        link
      }
    }
  }
`;

export default Footer;
