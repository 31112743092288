import React from 'react';
import { graphql } from 'gatsby';
import { FaCheck, FaLaptop, FaPiggyBank, FaHandsHelping } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const WhyHireMe = ({ title, highlights = [], className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="text-4xl mb-12 leading-tight text-center">{title}</div>
      <div className="flex flex-col items-center">
        <ul className="flex flex-col justify-center text-2xl font-black w-full lg:flex-row lg:mb-8">
          <li className="flex flex-col items-center flex-1 mb-12 text-center">
            <div className="flex">
              <FaLaptop className="text-light-500 text-6xl mb-2" />
            </div>
            <div>
              <span className="text-3xl font-normal leading-3">100</span>
              <span>{t('whyHireMe.onlineAndSafe')}</span>
            </div>
          </li>
          <li className="flex flex-col items-center flex-1 mb-12 text-center">
            <div className="flex">
              <FaHandsHelping className="text-light-500 text-6xl mb-2" />
            </div>
            <div>{t('whyHireMe.personalLawyer')}</div>
          </li>
          <li className="flex flex-col items-center flex-1 mb-12 text-center">
            <div className="flex">
              <FaPiggyBank className="text-light-500 text-6xl mb-2" />
            </div>
            <div>{t('whyHireMe.affordable')}</div>
          </li>
        </ul>
        <ul className="space-y-4 lg:w-2/3">
          {highlights.map(({ highlight }) => (
            <li key={highlight} className="font-serifdisplay flex text-xl">
              <FaCheck className="text-green-600 relative top-1 flex-none" />
              <span className="ml-2 font-normal text-lg">{highlight}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const WhyHireMeFragment = graphql`
  fragment WhyHireMeFragment on PrismicWhyHireMe {
    data {
      title {
        text
      }
      highlights {
        highlight
      }
    }
  }
`;

export default WhyHireMe;
