/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import siteMetadata from '../siteMetadata';
import { usePageContext } from '../contexts/page-context';
import EUFlag from '../img/european_union_flag.svg';
import USFlag from '../img/us_flag.svg';

const getCurrencyLabel = currency => (currency === 'USD' ? '$USD' : '€EUR');

const CurrencySelector = ({ onSelected = () => {}, size = 24, className }) => {
  const {
    pageContext: { currency },
    setCurrency,
  } = usePageContext();

  const onSelectedCurrency = event => {
    const selectedCurrency = event.target.value;

    setCurrency(selectedCurrency);
    onSelected(selectedCurrency);
  };

  if (!currency) {
    return null;
  }

  return (
    <div className={`flex items-center relative cursor-pointer ${className}`}>
      {currency === 'USD' ? (
        <img src={USFlag} alt="United States flag" className="w-6 absolute" />
      ) : (
        <img src={EUFlag} alt="European Union flag" className="w-6 absolute" />
      )}
      <select
        aria-label="currency"
        className="block w-full bg-transparent py-2 pr-6 pl-8 uppercase cursor-pointer focus:outline-none focus:underline appearance-none z-10"
        value={currency}
        onChange={onSelectedCurrency}
        style={{ 'WebkitAppearance': 'none' }}
      >
        {siteMetadata.currencies.map(c => (
          <option key={c} value={c}>{getCurrencyLabel(c)}</option>
        ))}
      </select>
      <FaAngleDown size={size} className="absolute right-0" />
    </div>
  );
};

export default CurrencySelector;
