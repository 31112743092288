import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const getLinks = ({ size }) => [
  {
    service: 'facebook',
    link: 'https://www.facebook.com/sabalierlaw',
    icon: <FaFacebook size={size} />,
  },
  {
    service: 'instagram',
    link: 'https://www.instagram.com/sabalierlaw',
    icon: <FaInstagram size={size} />,
  },
  {
    service: 'linkedin',
    link: 'https://www.linkedin.com/company/sabalierlaw',
    icon: <FaLinkedin size={size} />,
  },
];

const SocialMediaLinks = ({ size, className }) => {
  const links = getLinks({ size });

  return (
    <>
      {links &&
        links.map(l => (
          <a
            key={l.service}
            href={l.link}
            className={className}
            title={l.service}
            target="_blank"
            rel="noopener noreferrer"
          >
            {l.icon}
          </a>
        ))}
    </>
  );
};

export default SocialMediaLinks;
