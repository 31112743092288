module.exports = {
  title: {
    en: 'Sabalier Law - Online & Simple US Taxes For American Expats',
    es: 'Sabalier Law - Servicios Legales De Impuestos Para Expatriados Americanos',
  },
  description: {
    en: 'Online & Simple US Taxes For American Expats',
    es: 'Servicios Legales De Impuestos Para Expatriados Americanos',
  },
  siteUrl: 'https://www.sabalierlaw.com',
  site: 'Sabalier Law',
  author: 'info@sabalierlaw.com',
  keywords: {
    en: ['lawyer', 'remote lawyer', 'lawyer online', 'american taxes lawyer', 'expat', 'us taxes', 'us visas'],
    es: [
      'abogado',
      'abogado remoto ',
      'abogado online',
      'abogado de impuestos americanos',
      'expat',
      'taxes',
      'us visas',
    ],
  },
  langs: [
    { key: 'en-us', label: 'en' },
    { key: 'es-es', label: 'es' },
  ],
  defaultLang: 'en-us',
  currencies: ['EUR', 'USD'],
  defaultCurrency: 'EUR',
};
