/* eslint-disable no-restricted-globals */
const { flatten, unflatten } = require('flat');
const siteMetadata = require('./siteMetadata');

const getShortLang = (lang) => lang && lang.slice(0, 2);

const slugizy = (text) =>
  text
    ? text
        .toLowerCase()
        .replace(/^-+|-+$|[':"¿?.’`~]/g, '')
        .replace(/\s+/g, '-')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : '';

const getSlug = (blogPost) => (blogPost.data ? blogPost.data.slug || slugizy(blogPost.data.title) : '');

const getLangPath = (lang) => {
  const shortLang = getShortLang(lang);
  const shortDefaultLang = getShortLang(siteMetadata.defaultLang);

  return shortLang === shortDefaultLang ? '' : `/${shortLang}`;
};

const getPublicationDate = (blogPost) => blogPost.data.publication_date || blogPost.first_publication_date;

const reduceSlices = (slices) =>
  slices.reduce((result, slice) => {
    return {
      ...result,
      [slice.slice_type]: {
        primary: slice.primary,
        items: slice.items,
      },
    };
  }, {});

const getExcerpt = (content = '', wordlimit) => {
  const filter = content.replace(/\s+/g, ' ');
  const wordsarr = filter.split(' ');

  if (wordsarr.length < wordlimit) {
    return content;
  }

  const words = wordsarr.slice(0, wordlimit);

  return `${words.join(' ')}...`;
};

const getReviewsRate = (reviews = []) => {
  if (!reviews.length) {
    return 0;
  }

  const sum = reviews.reduce((total, review) => {
    if (!review.rating) {
      return total;
    }

    return total + review.rating;
  }, 0);

  return (sum / reviews.length).toFixed(2);
};

const isGoogleReviewsEnabled = () => process.env.IS_GOOGLE_REVIEWS_ENABLED === 'true';

const getLastPathElement = (path = '') => {
  const [lastPathElement] = path.split('/').filter(Boolean).slice(-1);

  return lastPathElement;
};

const convertFormDataToObject = (formData = {}, { fieldsIncluded = [] } = {}) => {
  const result = fieldsIncluded.reduce((previous, current) => {
    return {
      ...previous,
      [current]: null,
    };
  }, {});

  const formDataObject = unflatten(formData, { delimiter: '-' });

  return {
    ...result,
    ...formDataObject,
  };
};

const convertObjectToFormData = (object = {}) => {
  return flatten(object, { delimiter: '-', safe: true });
};

const formDataWithBoolean = (formData) =>
  Array.from(formData.entries(), ([key, value]) => {
    if (value === 'true' || value === 'false') {
      return [key, value === 'true'];
    }

    return [key, value];
  });

const getCssDelay = ({ index = 0, delay = 300, size = 'lg' }) => {
  const delayMs = index * delay;

  switch (delayMs) {
    case 300:
      return size === 'lg' ? 'lg:delay-300' : 'delay-300';
    case 600:
      return size === 'lg' ? 'lg:delay-600' : 'delay-600';
    case 900:
      return size === 'lg' ? 'lg:delay-900' : 'delay-900';
    case 1200:
      return size === 'lg' ? 'lg:delay-1200' : 'delay-1200';
    case 1500:
      return size === 'lg' ? 'lg:delay-1500' : 'delay-1500';

    default:
      return '';
  }
};

const formatDate = (dateStr = '') => {
  return Array.from(dateStr)
    .map((char, i) => {
      if (i === 2 || i === 5) {
        if (char === '/') {
          return '-';
        }

        if (char === '-') {
          return char;
        }

        return `-${char}`;
      }

      return char;
    })
    .join('');
};

const formatMoney = (str) => {
  const regex = /([0-9,]+(\.\d+)?)/;
  const match = str.match(regex);

  if (match) {
    const number = parseFloat(match[0].replace(/,/g, ''));
    const formattedNumber = number.toLocaleString('en-US');
    return str.replace(regex, formattedNumber);
  }

  return str;
};

exports.getShortLang = getShortLang;
exports.slugizy = slugizy;
exports.getSlug = getSlug;
exports.getLangPath = getLangPath;
exports.getPublicationDate = getPublicationDate;
exports.reduceSlices = reduceSlices;
exports.getExcerpt = getExcerpt;
exports.getReviewsRate = getReviewsRate;
exports.isGoogleReviewsEnabled = isGoogleReviewsEnabled;
exports.getLastPathElement = getLastPathElement;
exports.convertFormDataToObject = convertFormDataToObject;
exports.convertObjectToFormData = convertObjectToFormData;
exports.formDataWithBoolean = formDataWithBoolean;
exports.getCssDelay = getCssDelay;
exports.formatDate = formatDate;
exports.formatMoney = formatMoney;
